import { AdsorptionMeasurementService } from '../../services/compcat/adsorption-measurement';
import { ExperimentalDataService } from '../../services/compcat/experimental-data'
import { GPEDataService } from '../../services/compcat/gas-phase-energy'
import { v4 as uuidv4 } from 'uuid';
import _, { first, forEach, over } from 'lodash';

const state = {
    context: '',
    tableData: [],
    tableDataColumns: [],
    adsorptionMeasurements: [],
    jsonForDownload: {},
    isLoading: true,
    isAPIUnreachable: false,
    coefficientsAndAdsorbates: [],
    overallElementalFormula: [],
    overallFormula: '',
    elementalFormula: '',
    substrateText: ''
}

function _coefficientConversion(coefficient, formula) {
    // console.log('_coefficientConversion - coefficient: ', coefficient);
    // console.log('_coefficientConversion - formula: ', formula);

    let overallStr = '';

    if (coefficient == 0) {
        // if coefficient is 0, remove the symbol
        overallStr = '';
    }
    else if (coefficient == 1 || coefficient == -1) {
        // if this is 1 or -1 whole number, remove digit
        overallStr = (coefficient > 0) ? 
        formula :
        '–' + formula;
    }
    else if (coefficient % 1 == 0) {
        // if this is whole number, remove all zero decimal points
        overallStr = (Math.round(coefficient) + ' ' + formula);
    }
    else {
        // round float to 2 decimal point and convert to string to remove trailing zero
        overallStr = (Math.round(coefficient * 100)/100).toString() + ' ' + formula;
    }

    // console.log('_coefficientConversion - overallStr: ', overallStr);

    return overallStr;
}

function _elementalFormulaConversion(coefficientAndAdsorbate) {
    // console.log('_elementalFormulaConversion - coefficientAndAdsorbate: ', coefficientAndAdsorbate);

    let elementalFormulaArray = [];
    coefficientAndAdsorbate.forEach(pair => {            
        pair.species.elemental_formula.forEach(formula => {
            // compute kv for each elemental symbol and coefficients
            let element_kv = {};
            element_kv.coefficient = pair.coefficient * formula.coefficient;
            element_kv.symbol = formula.element.symbol;
            elementalFormulaArray.push(element_kv);
        });
    })
    // console.log('_elementalFormulaConversion - elementalFormulaArray: ', elementalFormulaArray);

    let formula = _(elementalFormulaArray)
        .groupBy((item) => item.symbol)
        .map((objs, key) => ({
            'symbol': key,
            'coefficient': _.sumBy(objs, 'coefficient')}))
        .value();

    // console.log('_elementalFormulaConversion - formula: ', formula);
    return formula;
}

function _overallFormula(coefficientAndAdsorbate) {
    // console.log('_overallFormula - coefficientAndAdsorbate: ', coefficientAndAdsorbate);

    let overallFormula = [];

    coefficientAndAdsorbate.forEach(pair => {
        let formulaString = _coefficientConversion(
            pair.coefficient, 
            pair.species.formula);
        overallFormula.push(formulaString);
    })
    // console.log('_overallFormula - overallFormula: ', overallFormula);

    return overallFormula.join(' + ').replace(' + -', ' – ').replace(' + –', ' – ');
}

function _coefficientFormula(referenceSpeciesSet) {
    // console.log('_coefficientFormula - referenceSpeciesSet: ', referenceSpeciesSet);

    let species = [];
    referenceSpeciesSet.forEach(referenceSpecies => {
        let formulaString = _coefficientConversion(
            parseFloat(referenceSpecies.reference_coefficient), 
            referenceSpecies.species.formula);
        species.push(formulaString);
    })
    // console.log('_coefficientFormula - species: ', species);
    
    return species.join(' + ').replace(' + -', ' – ').replace(' + –', ' – ');
}

function _elementalFormula(coefficentAndAdsorbate) {
    // console.log('_elementalFormula - coefficentAndAdsorbate: ', coefficentAndAdsorbate);

    let overallElementalFormula = _elementalFormulaConversion(coefficentAndAdsorbate);
    console.log("_elementalFormula - overallElementalFormula: ", overallElementalFormula);

    let overallElementalFormulaArray = [];
    overallElementalFormula.forEach(pair => {
        let formulaString = _coefficientConversion(
            pair.coefficient, 
            pair.symbol);
        overallElementalFormulaArray.push(formulaString);
        })
    
    console.log("_elementalFormula - overallElementalFormulaArray: ", overallElementalFormulaArray);

    return overallElementalFormulaArray.join('');
};


function _coverageUnit(adsorbateFraction, fractionType) {
    if (adsorbateFraction == null) {
        return '';
    }
    else {
        if (fractionType === 'fraction') {
            return (adsorbateFraction.fraction && adsorbateFraction.unit) ? 
                adsorbateFraction.fraction + ' per ' + adsorbateFraction.unit : '';
        }
        else if (fractionType === 'numeric') {
            return (adsorbateFraction.numeric != 0.0 && adsorbateFraction.unit) ? 
                adsorbateFraction.numeric + ' per ' + adsorbateFraction.unit : '';
        }
    }
}

function _substrateText(bulkFormula, firstLayer, secondLayer) {
    let substrateText = '';

    if (firstLayer == undefined && secondLayer == undefined) {
        substrateText = bulkFormula;
    }
    else if (firstLayer == undefined) {
        substrateText = secondLayer + '/' + bulkFormula;
    }
    else if (secondLayer == undefined) {
        substrateText = firstLayer + '/' + bulkFormula;
    }
    else {
        substrateText = firstLayer + '/' + secondLayer + '/' + bulkFormula;
    }
    return substrateText;
}

const getters = {
    tableData: (state) => {
        return state.tableData;
    },
    jsonForDownload: (state) => {
        return state.jsonForDownload;
    },
    tableDataColumns: (state) => {
        return state.tableDataColumns;
    },
    adsorptionMeasurementById: (state) => (id) => {
        return _.find(state.adsorptionMeasurements, {id: id});
    },
    isLoading: (state) => {
        return state.isLoading;
    },
    isAPIUnreachable: (state) => {
        return state.isAPIUnreachable;
    },
    coefficientFormula: (state) => (id) => {
        let adsorptionMeasurement = _.find(state.adsorptionMeasurements, {id: id});
        let referenceSpeciesSet = adsorptionMeasurement.adsorption_reference_species_set;
        return _coefficientFormula(referenceSpeciesSet);

    },
    adsorbateSpeciesById: (state) => (id) => {
        let adsorptionMeasurementRow = _.find(state.adsorptionMeasurements, {id: id});
        let adsorbateSpeciesRow = {}
        adsorbateSpeciesRow.name = (adsorptionMeasurementRow.adsorbate_species == null) ? null : adsorptionMeasurementRow.adsorbate_species.name;
        adsorbateSpeciesRow.smiles = (adsorptionMeasurementRow.adsorbate_species == null) ? null : adsorptionMeasurementRow.adsorbate_species.smiles;
        adsorbateSpeciesRow.formula = (adsorptionMeasurementRow.adsorbate_species == null) ? null : adsorptionMeasurementRow.adsorbate_species.formula;
        adsorbateSpeciesRow.connectivitySmiles = (adsorptionMeasurementRow.adsorbate_species == null) ? null : adsorptionMeasurementRow.adsorbate_species.connectivity_smiles;
        adsorbateSpeciesRow.molecularFormula = (adsorptionMeasurementRow.adsorbate_species == null) ? null : adsorptionMeasurementRow.adsorbate_species.molecular_formula;
        adsorbateSpeciesRow.adsorptionSite = (adsorptionMeasurementRow.adsorption_site == null) ? null : adsorptionMeasurementRow.adsorption_site.site_name;
        adsorbateSpeciesRow.fraction = (adsorptionMeasurementRow.adsorbate_fraction == null ) ? null : 
            _coverageUnit(adsorptionMeasurementRow.adsorbate_fraction, 'fraction');
        adsorbateSpeciesRow.numeric = (adsorptionMeasurementRow.adsorbate_fraction == null ) ? null : 
            _coverageUnit(adsorptionMeasurementRow.adsorbate_fraction, 'numeric');

        return adsorbateSpeciesRow;
    },
    materialPropertiesById: (state) => (id) => {
        let adsorptionMeasurementRow = _.find(state.adsorptionMeasurements, {id: id});
        let materialPropertiesRow = {}
        materialPropertiesRow.formula = (adsorptionMeasurementRow.bulk_surface_property_set.bulk_surface_material == null) ? null : adsorptionMeasurementRow.bulk_surface_property_set.bulk_surface_material.name;
        materialPropertiesRow.primaryClass = (adsorptionMeasurementRow.bulk_surface_property_set.primary_bulk_class == null) ? null : adsorptionMeasurementRow.bulk_surface_property_set.primary_bulk_class.name;
        materialPropertiesRow.secondaryClass = (adsorptionMeasurementRow.bulk_surface_property_set.secondary_bulk_class == null) ? null : adsorptionMeasurementRow.bulk_surface_property_set.secondary_bulk_class.name;
        materialPropertiesRow.stretched = adsorptionMeasurementRow.bulk_surface_property_set.is_stretched;
        materialPropertiesRow.compressed = adsorptionMeasurementRow.bulk_surface_property_set.is_compressed;
        materialPropertiesRow.spaceGroup = (adsorptionMeasurementRow.bulk_surface_property_set.space_group == null) ? null : adsorptionMeasurementRow.bulk_surface_property_set.space_group;
        materialPropertiesRow.latticeConstant = (adsorptionMeasurementRow.bulk_surface_property_set.lattice_constant == null) ? null : adsorptionMeasurementRow.bulk_surface_property_set.lattice_constant;

        return materialPropertiesRow;
    },
    surfacePropertiesById: (state) => (id) => {
        let adsorptionMeasurementRow = _.find(state.adsorptionMeasurements, {id: id});
        let surfacePropertiesRow = {}
        surfacePropertiesRow.nanoParticleSize = (adsorptionMeasurementRow.bulk_surface_property_set.nano_number_of_atoms == null) ? null : adsorptionMeasurementRow.bulk_surface_property_set.nano_number_of_atoms;
        surfacePropertiesRow.firstLayerComposition = (adsorptionMeasurementRow.bulk_surface_property_set.first_layer_composition == null) ? null : adsorptionMeasurementRow.bulk_surface_property_set.first_layer_composition.name;
        surfacePropertiesRow.secondLayerComposition = (adsorptionMeasurementRow.bulk_surface_property_set.second_layer_composition == null) ? null : adsorptionMeasurementRow.bulk_surface_property_set.second_layer_composition.name;
        surfacePropertiesRow.facet = (adsorptionMeasurementRow.bulk_surface_property_set.facet == null) ? null : adsorptionMeasurementRow.bulk_surface_property_set.facet.name;
        surfacePropertiesRow.termination = (adsorptionMeasurementRow.bulk_surface_property_set.termination == null) ? null : adsorptionMeasurementRow.bulk_surface_property_set.termination
        surfacePropertiesRow.cellSymmetry = (adsorptionMeasurementRow.bulk_surface_property_set.cell_symmetry == null) ? null : adsorptionMeasurementRow.bulk_surface_property_set.cell_symmetry.name;
        
        return surfacePropertiesRow;
    },
    adsorptionMethodsById: (state) => (id) => {
        let adsorptionMeasurementRow = _.find(state.adsorptionMeasurements, {id: id});
        let adsorptionMethodsRow = {}
        adsorptionMethodsRow.software = (adsorptionMeasurementRow.method.software == null) ? null : adsorptionMeasurementRow.method.software;
        adsorptionMethodsRow.exchangeCorrelation = (adsorptionMeasurementRow.method.exchange_correlation == null) ? null : adsorptionMeasurementRow.method.exchange_correlation;
        adsorptionMethodsRow.potentials = (adsorptionMeasurementRow.method.potentials == null) ? null : adsorptionMeasurementRow.method.potentials;
        adsorptionMethodsRow.basisSet = (adsorptionMeasurementRow.method.basis_set == null) ? null : adsorptionMeasurementRow.method.basis_set;
        adsorptionMethodsRow.spinPol = (adsorptionMeasurementRow.method.is_spin_polarization == null) ? null : adsorptionMeasurementRow.method.is_spin_polarization;
        adsorptionMethodsRow.zpe = (adsorptionMeasurementRow.method.is_zero_point_energy == null) ? null : adsorptionMeasurementRow.method.is_zero_point_energy;
        adsorptionMethodsRow.fixedSubstrate = (adsorptionMeasurementRow.method.is_fixed_substrate == null) ? null : adsorptionMeasurementRow.method.is_fixed_substrate;
        
        return adsorptionMethodsRow;
    },
    adsorptionMetadataById: (state) => (id) => {
        let adsorptionMeasurementRow = _.find(state.adsorptionMeasurements, {id: id});
        let adsorptionMetadataRow = {}
        adsorptionMetadataRow.doi = adsorptionMeasurementRow.doi;
        adsorptionMetadataRow.internalNote = adsorptionMeasurementRow.internal_note;
        adsorptionMetadataRow.externalNote = adsorptionMeasurementRow.external_note;
        adsorptionMetadataRow.emnUser = adsorptionMeasurementRow.emn_user.first_name + ' ' + adsorptionMeasurementRow.emn_user.last_name;
        adsorptionMetadataRow.email = adsorptionMeasurementRow.emn_user.email;
        adsorptionMetadataRow.affiliation = adsorptionMeasurementRow.emn_user.affilication;

        return adsorptionMetadataRow;
    },
    adsorptionEnergyById: (state) => (id) => {
        let adsorptionMeasurementRow = _.find(state.adsorptionMeasurements, {id: id});
        let adsorptionEnergyRow = {}
        adsorptionEnergyRow.energy = adsorptionMeasurementRow.adsorption_energy;
        adsorptionEnergyRow.mostStable = adsorptionMeasurementRow.is_most_stable_site;
        adsorptionEnergyRow.gpeInfo = adsorptionMeasurementRow.gpe_info;

        return adsorptionEnergyRow;
    },

    // Reference species interconversion
    coefficientsAndAdsorbates(state) {
        return state.coefficientsAndAdsorbates;
    },

    overallFormula(state) {
        return state.overallFormula;
    },

    elementalFormula(state) {
        return state.elementalFormula;
    },

    overallElementalFormula(state) {
        return state.overallElementalFormula;
    },
    
    substrateText(state) {
        return state.substrateText;
    }
}

const actions = {
    async downloadResultsJSON( { commit }, { filters } ) {
        try {
            commit ( 'setIsLoading', true);
            let data = [];
            if (state.context === 'adsorption') {
                data = await AdsorptionMeasurementService.list(filters, true);
            } else if (state.context === 'experimental') {
                data = await ExperimentalDataService.list(filters, true);
            } else if (state.context === 'gpe') {
                data = await GPEDataService.list(filters, true);
            }
            commit ( 'setJSONForDownload', data );
        } finally {
            commit ( 'setIsLoading', false);
        }
    },
    // async downloadExperimentalResultsJSON( { commit }, { filters } ) {
    //     try {
    //         commit ( 'setIsLoading', true);
    //         let data = await ExperimentalDataService.list(filters, true);
    //         commit ( 'setJSONForDownload', data );
    //     } finally {
    //         commit ( 'setIsLoading', false);
    //     }
    // },
    // async downloadGPEJSON( { commit }, { filters } ) {
    //     try {
    //         commit ( 'setIsLoading', true);
    //         let data = await GPEDataService.list(filters, true);
    //         commit ( 'setJSONForDownload', data );
    //     } finally {
    //         commit ( 'setIsLoading', false);
    //     }
    // },
    async changeTableDataContext( { commit }, { context } ) {
        console.log(context);
        commit ('setContext', context);
    },
    async fetchTableData( { commit }, { filters, tableDataColumns, rsi } ) {  
        try {
            commit ('setIsLoading', true);
            
            let rows = [];
            if (state.context === 'adsorption') {
                
                let rsi_conversion = typeof rsi !== 'undefined' && rsi;
                let for_download = false;

                let rsi_coefficient_adsorbates = rsi_conversion ? state.coefficientsAndAdsorbates
                    .filter(x => x.coefficient !== null)
                    .map(x => ({ coefficient: x.coefficient, species_id: x.species.id }))
                    : [];

                let rsi_elemental_formula = state.overallElementalFormula;

                let data = await AdsorptionMeasurementService.list(filters, for_download, rsi_conversion, rsi_coefficient_adsorbates, rsi_elemental_formula);
                //console.log('fetchTableData - data: ', data);

                commit ( 'setAdsorptionMeasurements', {data} );

                rows = _.map(data, (result) => {
                    let row = {};
                    _.chain(tableDataColumns)
                    .filter({visible:true})
                    .each((tableDataColumn) => {
                        if (tableDataColumn.field === 'reference_species') {
                            let referenceSpeciesSet = _.get(result, tableDataColumn.display_key); 
                            let formulaText = _coefficientFormula(referenceSpeciesSet);
                            row[tableDataColumn.field] = formulaText;
                        }
                        else if (tableDataColumn.field === 'adsorbate') {
                            let adsorbateSpecies = _.get(result, tableDataColumn.display_key);
                            row[tableDataColumn.field] =  adsorbateSpecies.formula;
                        }
                        else if (tableDataColumn.field === 'fraction' || tableDataColumn.field === 'numeric') {
                            let adsorbateFraction = _.get(result, tableDataColumn.display_key);
                            row[tableDataColumn.field] = _coverageUnit(adsorbateFraction, tableDataColumn.field);
                        }
                        else if (tableDataColumn.field === 'bulk_formula') {
                            let bulkFormula = _.get(result,'bulk_surface_property_set.bulk_surface_material.name');
                            let firstLayer = _.get(result, 'bulk_surface_property_set.first_layer_composition.name');
                            let secondLayer = _.get(result, 'bulk_surface_property_set.second_layer_composition.name');
                            row[tableDataColumn.field] = _substrateText(bulkFormula, firstLayer, secondLayer);

                            // sclark added this to debug first & second layers using the bulk surface property set id
                            // console.log(row[tableDataColumn.field], ' - bsps id: ', _.get(result, 'bulk_surface_property_set.id'), ' - res id: ', result.id);
                            // console.log('first layer: ', firstLayer, ' - second layer: ', secondLayer);
                            // console.log('result: ', JSON.stringify(result));
                        }
                        else {
                            row[tableDataColumn.field] = _.get(result, tableDataColumn.filter_key)
                        }
                    })
                    .value();

                    row['id'] = result.id;

                    if (typeof result.rsi !== undefined) {
                        row['rsi'] = result.rsi;
                    }

                    return row;
                });

                // sort by bulk formula
                rows.sort((a, b) => (a.bulk_formula > b.bulk_formula) ? 1 : -1);

                //console.log('fetchTableData - rows: ', rows);
            
            } else if (state.context === 'experimental') {
              
                let for_download = false;
                rows = await ExperimentalDataService.list(filters, for_download);
            
            } else if (state.context === 'gpe') {
                
                let for_download = false;
                let data = await GPEDataService.list(filters, for_download);
                //console.log('fetchTableData - data: ', data);

                try {
                    rows = _.map(data, (result) => {
                        let row = {};
                        _.chain(tableDataColumns)
                        .filter({visible:true})
                        .each((tableDataColumn) => {
                            row[tableDataColumn.field] = _.get(result, tableDataColumn.filter_key)
                        })
                        .value();

                        row['id'] = result.id;

                        return row;
                    });
                }
                catch (e) {
                    console.log(e)
                }
                //console.log(rows)

            } 

            commit ( 'setTableData', rows );
            commit ( 'setIsAPIUnreachable', false);
        } catch {
            commit ( 'setIsAPIUnreachable', true);
        } finally {
            commit ( 'setIsLoading', false);
        }
    },
    // async fetchExperimentalTableData( { commit }, { filters, tableDataColumns } ) {  
    //     try {
    //         commit ('setIsLoading', true);

    //         let rows = await ExperimentalDataService.list();
    //         //console.log('fetchTableData - rows: ', rows);

    //         commit ( 'setTableData', rows );
    //         commit ( 'setIsAPIUnreachable', false);
    //     } catch {
    //         commit ( 'setIsAPIUnreachable', true);
    //     } finally {
    //         commit ( 'setIsLoading', false);
    //     }
    // },
    // async fetchGPETableData( { commit }, { filters, tableDataColumns } ) {  
    //     try {
    //         commit ('setIsLoading', true);

    //         let data = await GPEDataService.list();
    //         //console.log('fetchTableData - data: ', data);

    //         let rows = [];
    //         try {
    //             rows = _.map(data, (result) => {
    //                 let row = {};
    //                 _.chain(tableDataColumns)
    //                 .filter({visible:true})
    //                 .each((tableDataColumn) => {
    //                     row[tableDataColumn.field] = _.get(result, tableDataColumn.filter_key)
    //                 })
    //                 .value();

    //                 row['id'] = result.id;

    //                 return row;
    //             });
    //         }
    //         catch (e) {
    //             console.log(e)
    //         }
    //         //console.log(rows)

    //         commit ( 'setTableData', rows );
    //         commit ( 'setIsAPIUnreachable', false);
    //     } catch {
    //         commit ( 'setIsAPIUnreachable', true);
    //     } finally {
    //         commit ( 'setIsLoading', false);
    //     }
    // },
    async setTableDataColumns( {commit}, {searchCriteria}) {
        let tableDataColumns_unordered = _.map(searchCriteria, 
            (searchCriterion) => {
                return {      
                    filter_key: searchCriterion.filter_key,
                    display_key: searchCriterion.display_key,
                    display_order: searchCriterion.display_order,
                    field: searchCriterion.display_field,
                    label: searchCriterion.display_label,
                    tooltip: searchCriterion.tooltip_text,
                    visible: searchCriterion.visible,
                    default: searchCriterion.default,
                    sortable: true,
                    searchable: false
                };          
            });
        let tableDataColumns = _.sortBy(tableDataColumns_unordered, obj => obj.display_order);
        commit ( 'setTableDataColumns', tableDataColumns);                        
    },
    toggleColumnVisible( {commit}, {fieldName, visible, visibility_type} ) {
        let index = _.findIndex(state.tableDataColumns, {field: fieldName} );
        if (fieldName == 'first_layer' || fieldName == 'second_layer') {
            visible = false;
        }
        commit ( 'toggleColumnVisible', {index, visible, visibility_type});
    },
    resetColumnVisible( {commit}, {fieldName} ) {
        let index = _.findIndex(state.tableDataColumns, {field: fieldName});
        commit ( 'resetColumnVisible', {index} );
    },
    updateColumnVisible( {commit}, {fieldName, visible} ) {
        let index = _.findIndex(state.tableDataColumns, {field: fieldName});
        commit ( 'updateColumnVisible', {index, visible});
    },

    // Reference species interconversion
    resetCoefficientsAndAdsorbates({ commit }) {
        const data = {
            uuid: uuidv4(),
            species: null,
            coefficient: null
        };
        commit('resetCoefficientsAndAdsorbates', { data });
    },

    addBlankCoefficientAndAdsorbate({ commit }) {
        const data = {
            uuid: uuidv4(),
            species: null,
            coefficient: null
        };
        commit('addBlankCoefficientAndAdsorbate', { data });
    },

    removeCoefficientAndAbsorbate({ commit }, { uuid }) {
        const index = _.findIndex(state.coefficientsAndAdsorbates, (el) => el.uuid === uuid);
        commit('removeCoefficientAndAdsorbate', { index });
    },

    updateCoefficient({ commit }, { uuid, coefficient }) {
        const index = _.findIndex(state.coefficientsAndAdsorbates, (el) => el.uuid === uuid);
        commit('updateCoefficient', { index, coefficient });
    },

    updateSpecies({ commit }, { uuid, species }) {
        const index = _.findIndex(state.coefficientsAndAdsorbates, (el) => el.uuid === uuid);
        commit('updateSpecies', { index, species });
    },

    updateOverallFormula({ commit }) {
        // console.log('updateOverallFormula');

        // refactor to use the same algorithm as adsorbate species sets overall formula
        let coefficentAndAdsorbate = state.coefficientsAndAdsorbates
            .filter((pair) => pair.coefficient !== null && pair.species !== null);

        let overallFormula =  _overallFormula(coefficentAndAdsorbate);
        commit('updateOverallFormula', { overallFormula });

    },

    updateElementalFormula({ commit } ) {
        // console.log('updateElementalFormula');

        let coefficentAndAdsorbate = state.coefficientsAndAdsorbates
            .filter((pair) => pair.coefficient !== null && pair.species !== null);

        let elementalFormulaStr = _elementalFormula(coefficentAndAdsorbate);
        let overallElementalFormulas = _elementalFormulaConversion(coefficentAndAdsorbate);
        commit('updateElementalFormula', { elementalFormulaStr, overallElementalFormulas });

    }
    // updateSubstrateText({ commit }) {
    //     let bulkFormula = 'Pt3Fe';
    //     let firstLayer = 'Cu';
    //     let secondLayer = 'Cu';

    //     let substrateText = _substrateText(bulkFormula, firstLayer, secondLayer);
    //     commit ('updateSubstrateText', { substrateText });

    // }
}

const mutations = {
    setContext(state, context) {
        state.context = context;
    },
    setTableData ( state, data ) {
        state.tableData = data;
    },
    setTableDataColumns ( state, dataColumns ) {
        state.tableDataColumns = dataColumns;
    },
    toggleColumnVisible( state, {index, visible, visibility_type}) {
        _.set(state, `tableDataColumns[${index}].${visibility_type}`, visible);
        _.set(state, `tableDataColumns[${index}].visible`, state.tableDataColumns[index].search_visible || state.tableDataColumns[index].display_visible || false);
    },
    resetColumnVisible( state, {index} ) {
        _.set(state, `tableDataColumns[${index}].visible`, state.tableDataColumns[index].search_visible || state.tableDataColumns[index].default || false);
    },
    updateColumnVisible( state, {index, visible} ) {
        _.set(state, `tableDataColumns[${index}].display_visible`, visible);
        _.set(state, `tableDataColumns[${index}].visible`, state.tableDataColumns[index].display_visible);
    },
    setAdsorptionMeasurements( state, {data} ) {
        state.adsorptionMeasurements = data;
    },
    setJSONForDownload ( state, data ) {
        state.jsonForDownload = data;
    },
    setIsLoading( state, isLoading ) {
        state.isLoading = isLoading
    },
    setIsAPIUnreachable( state, isAPIUnreachable ) {
        state.isAPIUnreachable = isAPIUnreachable
    },

    // Reference species interconversion
    resetCoefficientsAndAdsorbates(state, { data }) {
        state.coefficientsAndAdsorbates = [data];
    },

    addBlankCoefficientAndAdsorbate(state, { data }) {
        state.coefficientsAndAdsorbates = [
            ...state.coefficientsAndAdsorbates,
            data
        ];
    },

    removeCoefficientAndAdsorbate(state, { index }) {
        state.coefficientsAndAdsorbates.splice(index, 1);
    },

    updateCoefficient(state, { index, coefficient }) {
        const coefficentAndAdsorbate = state.coefficientsAndAdsorbates[index];
        coefficentAndAdsorbate.coefficient = coefficient;
    },

    updateSpecies(state, { index, species }) {
        const coefficientAndAdsorbate = state.coefficientsAndAdsorbates[index];
        coefficientAndAdsorbate.species = species;
        console.log(coefficientAndAdsorbate.species);
    },

    updateConvertedAdsorptionMeasurementRows(state, { updatedAdsorptionMeasurements }) {
        state.updatedAdsorptionMeasurements = updatedAdsorptionMeasurements;
    },

    updateOverallFormula(state, { overallFormula }) {
        state.overallFormula = overallFormula;
    },

    updateElementalFormula(state, { elementalFormulaStr, overallElementalFormulas }) {
        state.elementalFormula = elementalFormulaStr;
        state.overallElementalFormula = overallElementalFormulas;
    },
    
    updateSubstrateText(state, { substrateText }) {
        state.substrateText = substrateText;
    }
}

const adsorptionMeasurement = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default adsorptionMeasurement;
