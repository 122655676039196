<template>
  <b-navbar wrapper-class="container" type="is-light">
    <!-- <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <b-icon icon="hexagon-multiple" size="is-medium" type="is-primary"></b-icon>
      </b-navbar-item>
    </template> -->

    <!-- <template slot="start">
      <b-navbar-item tag="a">
        <router-link to="/healthcheck">
          <strong>Healthcheck</strong>
        </router-link>
      </b-navbar-item>
    </template> -->
    <template slot="start">
      <b-navbar-item tag="router-link" to="/" :active="$route.name === 'home'">
        <strong>Home</strong>
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/search_database" :active="$route.name === 'search_database'">
        <strong>Adsorption Data</strong>
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/search_database_experimental" :active="$route.name === 'search_database_experimental'">
        <strong>Experimental Data</strong>
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/search_database_gpe" :active="$route.name === 'search_database_gpe'">
        <strong>Gas Phase Energies</strong>
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/parameter-guide" :active="$route.name === 'parameter-guide-index'">
        <strong>Parameter Guide</strong>
      </b-navbar-item>
      <b-navbar-item>
        <a class="has-text-dark has-text-weight-bold" href="https://cpd-docs.chemcatbio.org" target="_blank">User Guide</a>
      </b-navbar-item>
    </template>
    <template slot="end">
      <!-- TODO: remove this until we need to update DB -->
      <!-- <b-navbar-dropdown v-if="loggedIn" right>
        <template slot="label">
          <b-icon icon="account-box"></b-icon>
          <span>{{cognitoUser.username}}</span>
        </template>
        <b-navbar-item tag="router-link" :to="{ path: '/user' }">
          <b-icon icon="account-badge-outline"></b-icon>
          <span>Details</span>
        </b-navbar-item>
        <a class="navbar-item" @click="submitLogout()">
          <b-icon icon="skull"></b-icon>
          <span>Sign Out</span>
        </a>
      </b-navbar-dropdown>

      <b-navbar-item v-else tag="div">
        <div class="buttons">
          <b-button class="is-light" @click="login">
            <b-icon icon="lock"></b-icon>
            <span>Sign In</span>
          </b-button>
        </div>
      </b-navbar-item> -->
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'nav-bar',
  computed: {
    ...mapGetters('auth', [
      'loggedIn',
      'cognitoUser',
      'config',
      'currentAuthenticatedUser',
      'selectedAppClient'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
    submitLogout: async function() {
      await this.logout();
    },
    login: function() {
      this.$Amplify.Auth.federatedSignIn();
    }
  }
}
</script>
