import rp from 'request-promise';
import config from '../../config';
import store from '../../store';

let getBaseUrl = () => {
    return config.apiUrl;
}

let getBearerToken = () => {
    return `Bearer ${store.getters['auth/currentAuthenticatedUser'].signInUserSession.idToken.jwtToken}`
}

const ExperimentalDataService = {
    list: async (filter={}, for_download=false) => {
        try {
            let requestOptions = {
                url: getBaseUrl() + 'v2/experimental_data',
                qs: {
                    'filter': JSON.stringify(filter),
                    for_download
                },
                json: true,
                method: 'GET'
            };

            let response = await rp( requestOptions );
        
            return response;
        } catch (error) {
            console.error('ExperimentalDataService.list error', error)
        }
    },
    get_search_criteria: async () => {
        let requestOptions = {
            url: getBaseUrl() + 'v2/experimental_search_criteria',
            json: true,
            method: 'GET'
        };

        let response = await rp( requestOptions );

        return response;
    },

    
}

export { ExperimentalDataService };
